import * as React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {

  constructor(props) {
    super(props);
    this.state = { isValidated: false };
    this.OtherTextFieldActive = false;
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === 'contact-type') {
      if (e.target.value === 'Other') {
        this.OtherTextFieldActive = true;
      } else {
        this.OtherTextFieldActive = false;
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        if (document.getElementById('contact-type').value==='I have items to donate') {
          window.emailjs.sendForm('service_ai9qk15', 'template_ek95jnf', document.getElementById('contact-form'))
            .then(function() {
              console.log('SUCCESS!');
            }, function(error) {
              console.log('FAILED...', error);
            });
        }
        navigate(form.getAttribute("action"));
      })
      .catch((error) => alert(error));
  };

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <div className="section">
                <div className="columns">
                  <div className="column is-7 is-offset-1">
                    <h1>Contact Us</h1>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-10 is-offset-1">
                    <form
                      id="contact-form"
                      name="contact"
                      method="post"
                      action="/contact-us-thanks"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={this.handleSubmit}
                    >
                      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                      <input type="hidden" name="form-name" value="contact" />
                      <div hidden>
                        <label>
                          Don’t fill this out:{" "}
                          <input name="bot-field" onChange={this.handleChange} />
                        </label>
                      </div>
                      <div>
                        <div className="field">
                          <label className="label" htmlFor={"contact-type"}>
                            Which best describes you?
                          </label>
                          <div className="control select">
                            <select
                              name={"contact-type"}
                              onChange={this.handleChange}
                              id={"contact-type"}
                              required={true}
                            >
                              <option></option>
                              <option>Parent in need</option>
                              <option>Professional enquiry/referral</option>
                              <option>I have items to donate</option>
                              <option>Other</option>
                            </select>
                          </div>
                          <div className="field">
                            <label className="label" htmlFor={"other"}>
                              Other
                            </label>
                            <div className="control">
                              <input
                                className="input"
                                type={"text"}
                                name={"other"}
                                onChange={this.handleChange}
                                id={"other"}
                                required={this.OtherTextFieldActive}
                                disabled={!this.OtherTextFieldActive}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field">
                        <label className="label" htmlFor={"name"}>
                          Your name
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={"text"}
                            name={"name"}
                            onChange={this.handleChange}
                            id={"name"}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label" htmlFor={"contact-number"}>
                        Contact Number
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={"telephone"}
                            name={"contact-number"}
                            onChange={this.handleChange}
                            id={"contact-number"}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label" htmlFor={"email"}>
                          Email
                        </label>
                        <div className="control">
                          <input
                            className="input"
                            type={"email"}
                            name={"email"}
                            onChange={this.handleChange}
                            id={"email"}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <label className="label" htmlFor={"message"}>
                          Message
                        </label>
                        <div className="control">
                          <textarea
                            className="textarea"
                            name={"message"}
                            onChange={this.handleChange}
                            id={"message"}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="field">
                        <button className="button is-link" type="submit">
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
